import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { currentUserIsAdminSelector } from '../../../redux/sections/shared/shared.selectors';
import routesConfig from '../../../utils/constants/routesConfig';
import useAuth from '../../../utils/hooks/useAuth';
import useSelector from '../../../utils/hooks/useSelector';
import useCurrentUser from '../../account/utils/hooks/useCurrentUser';

const Auth = ({ children }) => {
  const { isLoadingAuth } = useAuth();
  const router = useRouter();
  const { isLoadingCurrentUser } = useCurrentUser();
  const isAdmin = useSelector(currentUserIsAdminSelector);
  useEffect(() => {
    const checkIfCanViewPage = () => {
      const actualPage = Object.values(routesConfig).find(
        route => `/${route.slug}` === router.pathname
      );
      if (actualPage?.permission === 'admin') {
        if (!isLoadingCurrentUser && !isAdmin) {
          router.push('/');
        }
      }
    };
    checkIfCanViewPage();
  }, [isAdmin, isLoadingCurrentUser, router]);

  return isLoadingAuth ? (
    <div className="first-loading">
      <img
        className="first-loading__logo"
        src="/logo.svg"
        alt="DeepUpSell logo"
      />
    </div>
  ) : (
    children
  );
};

Auth.propTypes = { children: PropTypes.node.isRequired };

export default Auth;
