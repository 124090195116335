import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Layout } from '../components/shared/layout/Layout';
import Provider from '../redux/Provider';
import ErrorController from '../components/shared/errors/ErrorController';
import ConfigProvider from '../i18n/components/ConfigProvider/ConfigProvider';
import Auth from '../components/shared/auth/Auth';
import { pageViewer } from '../GoogleAnalytics';
import '../styles/index.scss';
import '../styles/antd-custom.less';

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', pageViewer);
    return () => {
      router.events.off('routeChangeComplete', pageViewer);
    };
  }, [router]);

  return router.route === '/login' || router.route === '/create-account' ? (
    <Layout>
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...pageProps}
      />
    </Layout>
  ) : (
    <Provider>
      <ErrorController>
        <Auth>
          <ConfigProvider>
            <Layout>
              <Component
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...pageProps}
              />
            </Layout>
          </ConfigProvider>
        </Auth>
      </ErrorController>
    </Provider>
  );
};

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.objectOf(Object).isRequired
};
MyApp.defaultProps = {
  Component: undefined
};

export default appWithTranslation(MyApp);
