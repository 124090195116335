export const readUsersApiParams = {
  endpoint: 'users',
  method: 'GET'
};

export const createUserApiParams = {
  endpoint: 'usersById',
  method: 'POST'
};

export const updateUserApiParams = {
  endpoint: 'usersById',
  method: 'PATCH'
};

export const deleteUsersApiParams = {
  endpoint: 'users',
  method: 'DELETE'
};
export const deleteUserApiParams = {
  endpoint: 'usersById',
  method: 'DELETE'
};

export const changeUserPasswordApiParams = {
  method: 'PATCH',
  endpoint: 'usersChangePasswordById'
};
