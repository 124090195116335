import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import useLicences from '../../components/account/licenses/utils/hooks/useLicences';
import useCurrentUser from '../../components/account/utils/hooks/useCurrentUser';
import { isArrayWithItems } from '../functions/validator/arrayValidator';

const useAuth = () => {
  const router = useRouter();
  const { currentUserInfo, isLoadingCurrentUser } = useCurrentUser();
  const { licensesActive, isLoadingReadLicencesActive } = useLicences();

  useEffect(() => {
    const goToLogin = () => {
      router.replace('/login');
    };
    const goToAccount = () => {
      if (router.route === '/account') {
        return;
      }
      router.replace('/account');
    };
    const checkIfHaveLicensesActive = () => {
      if (isLoadingReadLicencesActive) {
        return;
      }
      if (!isArrayWithItems(licensesActive)) {
        goToAccount();
        return;
      }
      const [licenseByDefault] = licensesActive;
      if (!licenseByDefault.userHasAccess) {
        goToAccount();
        return;
      }
      if (!licenseByDefault.quantity >= 1) {
        goToAccount();
      }
    };
    const checkIfIsLogin = () => {
      if (isLoadingCurrentUser) {
        return;
      }
      if (!currentUserInfo?.id) {
        goToLogin();
        return;
      }
      checkIfHaveLicensesActive();
    };
    checkIfIsLogin();
  }, [
    currentUserInfo,
    isLoadingCurrentUser,
    isLoadingReadLicencesActive,
    licensesActive,
    router
  ]);

  const isLoadingAuth = useMemo(
    () => isLoadingCurrentUser || isLoadingReadLicencesActive,
    [isLoadingCurrentUser, isLoadingReadLicencesActive]
  );
  return { isLoadingAuth };
};

export default useAuth;
