
export const readCurrentUsersApiParams = {
  method: 'GET',
  endpoint: 'currentUser'
};

export const updateCurrentUsersApiParams = {
  method: 'PATCH',
  endpoint: 'currentUser'
};
