import * as Sentry from '@sentry/react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'next-i18next';
import Button from '../button/Button';
import ResultMessage from '../info/resultMessage/ResultMessage';

class ErrorController extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    Sentry.captureException(error);
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error, { extra: info });
  }

  render() {
    const { hasError } = this.state;
    const { children, t: translation } = this.props;
    if (hasError) {
      return (
        <ResultMessage
          status="warning"
          title={translation('error.title')}
          subTitle={translation('error.subTitle')}
          extra={[
            <Button
              type="primary"
              key="reset"
              className="uppercase"
              onClick={() => window.location.reload()}
            >
              {translation('error.btnReload')}
            </Button>,
            <a href="/" key="home">
              <Button className="uppercase">
                {translation('error.btnHome')}
              </Button>
            </a>
          ]}
        />
      );
    }

    return children;
  }
}

ErrorController.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line id-length
  t: PropTypes.func.isRequired
};

export default withTranslation()(ErrorController);
