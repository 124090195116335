import { useUpdateEffect } from 'ahooks';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import useTranslation from '../../../../i18n/hooks/useTranslation';
import { setCurrentUserActionCreator } from '../../../../redux/sections/shared/shared.actionCreators';
import useRequest from '../../../../utils/hooks/useRequest';
import {
  changeUserPasswordApiParams,
  updateUserApiParams
} from '../../../establishments/api/usersApiParams';
import Fetch from '../../../shared/api/Fetch';
import successNotification from '../../../shared/notifications/successNotification';
import { readCurrentUsersApiParams } from '../../api/currentUserApiParams';

const useCurrentUser = () => {
  const { translation } = useTranslation();
  const router = useRouter();

  const { data: currentUserInfo, isLoading, run: readCurrentUser } = useRequest(
    {
      service: () => Fetch(readCurrentUsersApiParams)
    }
  );
  const {
    run: updateCurrentUser,
    isLoading: isLoadingUpdateCurrentUser
  } = useRequest({
    service: fetchConfig =>
      Fetch({
        ...updateUserApiParams,
        ...fetchConfig,
        params: [{ name: '$id', value: currentUserInfo.id }]
      }),
    manual: true,
    onSuccess: () => {
      readCurrentUser();
      successNotification(translation('general.updateSuccess'));
    }
  });

  const {
    run: updatePasswordCurrentUser,
    isLoading: isUpdatePasswordCurrentUser
  } = useRequest({
    service: fetchConfig =>
      Fetch({
        ...changeUserPasswordApiParams,
        ...fetchConfig,
        params: [{ name: '$id', value: currentUserInfo.id }]
      }),
    manual: true,
    onSuccess: () => {
      successNotification(translation('account.changePassword.updateSuccess'));
    }
  });

  useUpdateEffect(() => {
    setCurrentUserActionCreator(currentUserInfo);
    const { lang } = currentUserInfo;
    router.push('', '', { locale: lang });
    moment.locale(lang);
  }, [currentUserInfo]);
  const currentUser = useMemo(
    () => ({
      currentUserInfo,
      isLoadingCurrentUser: isLoading,
      updateCurrentUser,
      isLoadingUpdateCurrentUser,
      updatePasswordCurrentUser,
      isUpdatePasswordCurrentUser
    }),
    [
      currentUserInfo,
      isLoading,
      isLoadingUpdateCurrentUser,
      updateCurrentUser,
      updatePasswordCurrentUser,
      isUpdatePasswordCurrentUser
    ]
  );
  return currentUser;
};

export default useCurrentUser;
