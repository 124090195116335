import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider as ConfigProviderAntd } from 'antd';
import languageOptions from './utils/constants/languageOptions';
import useSelector from '../../../utils/hooks/useSelector';
import { currentUserLangSelector } from '../../../redux/sections/shared/shared.selectors';

const ConfigProvider = props => {
  const { children } = props;
  const lang = useSelector(currentUserLangSelector);
  const selectedLanguage = useMemo(() => lang, [lang]);
  const language = useMemo(() => languageOptions[selectedLanguage], [
    selectedLanguage
  ]);

  return <ConfigProviderAntd locale={language}>{children}</ConfigProviderAntd>;
};

ConfigProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(Object),
    PropTypes.arrayOf(Array),
    PropTypes.node,
    PropTypes.number
  ])
};

ConfigProvider.defaultProps = {
  children: undefined
};

export default ConfigProvider;
