import PropTypes from 'prop-types';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import store from './store';

const Provider = ({ children }) => {
  return <ReduxProvider store={store}>{children}</ReduxProvider>;
};

Provider.propTypes = {
  children: PropTypes.node.isRequired
};

export default Provider;
